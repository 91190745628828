import React from "react"
import "./services.css"

function ServicesDescription() {
  return (
    <div className="services-page-description-container">
      <div className="services-page-description-solutions">
        <h2 className="services-page-description-content-header">
          Our Solutions
        </h2>
        <span className="services-page-description-solution-text">
          Let profesional developers amplify your digital presence with tailored
          Software development services
        </span>
      </div>
      <div className="services-page-description-content-container">
        <h2 className="services-page-description-content-header">Services</h2>
        <div className="services-page-description-content-text-container">
          <span className="services-page-description-content-item-number">
            01
          </span>
          <span className="services-page-description-content-item-text">
            Design
          </span>
        </div>
        <div className="services-page-description-content-text-container">
          <span className="services-page-description-content-item-number">
            02
          </span>
          <span className="services-page-description-content-item-text">
            Web development
          </span>
        </div>
        <div className="services-page-description-content-text-container">
          <span className="services-page-description-content-item-number">
            03
          </span>
          <span className="services-page-description-content-item-text">
            Mobile development
          </span>
        </div>
        <div className="services-page-description-content-text-container">
          <span className="services-page-description-content-item-number">
            04
          </span>
          <span className="services-page-description-content-item-text">
            Hire an expert
          </span>
        </div>
      </div>
    </div>
  )
}

export default ServicesDescription
