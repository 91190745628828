import { Link } from "gatsby"
import React from "react"
import "./services.css"

function ServicesHero() {
  return (
    <div className="services-hero-container">
      <div class="services-hero-intro">
        Our{" "}
        <span
          style={{
            color: "#2d9cdb",
          }}
        >
          Services
        </span>
      </div>
      <div class="services-hero-header">
        We build world wide software solutions
      </div>
      <div class="services-hero-subtitle">
        Establish a strong digital presence with custom software design and
        development services. Skyrocket your business with software that's
        attractive, fully-functional, and responsive.
      </div>
      <Link
        to="/services#service-page-inquiry"
        style={{
          textDecoration: "none",
        }}
      >
        <button className="about-hero-button">Get Started</button>
      </Link>
    </div>
  )
}

export default ServicesHero
