import React from "react"
import { Layout } from "../components/global"
import ServicesDescription from "../components/Services/ServicesDescription"
import ServicesHero from "../components/Services/ServicesHero"
import ServicesSectionComponent from "../components/Services/ServicesSectionComponent"
import DesignImage from "../../static/assets/services/services-component/UI-UX.png"
import WebDevImage from "../../static/assets/services/services-component/web-development.png"
import MobileDevImage from "../../static/assets/services/services-component/mobile-development.png"
import ServicesFormSection from "../components/Services/ServicesFormSection"
import HomeAction from "../components/Home/HomeAction"
import { Helmet } from "react-helmet"

const services = () => {
  return (
    <Layout>
      <Helmet>
        <title>Keyla Technologies - Services</title>
      </Helmet>
      <ServicesHero />
      <ServicesDescription />
      <ServicesSectionComponent
        header="Design"
        subtitle="Unmatched quality is our top priority. Over the years, we’ve refined our design process and approach to collaboration 
 to ensure that we’re aligned with our partner’s vision at every step of our journey."
        listItems={[
          "UX Design",
          "Visual Design",
          "Interaction Design",
          "Prototyping",
        ]}
        footerText={
          <span>
            Read through our{" "}
            <a
              style={{
                color: "#2D9CDB",
              }}
              href="#/"
            >
              Design process
            </a>
          </span>
        }
        image={DesignImage}
        imageText="UI/UX Design"
      />
      <ServicesSectionComponent
        header="Web development"
        subtitle="Web development is a very fast-moving discipline and we never stop looking for what’s next while remaining laser-focused on delivering stability, great UX, performance, accessibility and security."
        listItems={["React, Angular, Next.js", "Redux", "CSS-in-JS"]}
        footerText={
          <span>
            Read through our{" "}
            <a
              style={{
                color: "#2D9CDB",
              }}
              href="#/"
            >
              Focus point of a successful web app
            </a>
          </span>
        }
        image={WebDevImage}
        imageText="Web development"
      />
      <ServicesSectionComponent
        header="Mobile development"
        subtitle="Our culture of knowledge sharing is the number one thing that defines our iOS and Android teams. 
 Learning from each other is a part of everything we do from weekly team meetings to external events."
        listItems={[
          "iOS",
          "Android",
          "React Native",
          "Flutter",
          "Progressive Web App",
        ]}
        footerText={
          <span>
            <a
              style={{
                color: "#2D9CDB",
              }}
              href="#/"
            >
              Is Native, React Native, Flutter or PWA{" "}
            </a>
            right for you?
          </span>
        }
        image={MobileDevImage}
        imageText="Mobile development"
      />
      <ServicesFormSection />
      <HomeAction />
    </Layout>
  )
}

export default services
