import React from "react"

function ServicesSectionComponent(props) {
  return (
    <div className="services-section-component-container">
      <div className="services-section-component-text-container">
        <h2 className="services-section-component-header">{props.header}</h2>
        <span className="services-section-component-subtitle">
          {props.subtitle}
        </span>
        <h4 className="services-section-component-list-header">What we do</h4>
        <div className="services-section-component-list-container">
          {props.listItems?.map(item => (
            <div className="services-section-component-list-item-container">
              <div className="services-section-component-list-item-line" />
              <div className="services-section-component-list-item-text">
                {item}
              </div>
            </div>
          ))}
        </div>
        <div className="services-section-component-text-footer-container">
          {/* <span className="services-footer-text">{props.footerText}</span> */}
        </div>
      </div>
      <div className="services-section-component-image-container">
        <img
          src={props.image}
          alt={props.header}
          className="services-section-component-image"
        />
        <div className="services-section-component-image-text">
          {props.imageText}
        </div>
      </div>
    </div>
  )
}

export default ServicesSectionComponent
