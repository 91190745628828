import React from "react"
// import ContactInquiry from "../Home/HomeInquiry/ContactInquiry"
// import PlatformInquiry from "../Home/HomeInquiry/PlatformInquiry"
// import ServiceInquiry from "../Home/HomeInquiry/ServiceInquiry"
import "./services.css"

function ServicesFormSection() {
  // Services
  // const [services, setServices] = React.useState([])

  // const addService = value => {
  //   if (!services.find(item => item === value)) {
  //     setServices([...services, value])
  //   } else {
  //     setServices(services.filter(item => item !== value))
  //   }
  // }

  // Platforms
  // const [platforms, setPlatforms] = React.useState([])

  // const addPlatform = value => {
  //   if (!platforms.find(item => item === value)) {
  //     setPlatforms([...platforms, value])
  //   } else {
  //     setPlatforms(platforms.filter(item => item !== value))
  //   }
  // }

  // const [contactValues, setContactValues] = React.useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   phone: "",
  //   companyName: "",
  //   projectDescription: "",
  // })

  // const changeContactValues = e => {
  //   setContactValues({
  //     ...contactValues,
  //     [e.target.name]: e.target.value,
  //   })
  // }

  // const submitValues = () => {
  //   alert("Your request is being processed")
  //   console.log(services, platforms, contactValues)
  // }
  return (
    <div className="services-form-container">
      <div
        className="services-form-content-container"
        id="service-page-inquiry"
      >
        <h2
          className="inquiry-header"
          style={{
            fontSize: 48,
            textAlign: "left",
            marginBottom: 40,
          }}
        >
          Ready to start a project?
        </h2>
        <h3
          className="inquiry-header"
          style={{
            textAlign: "left",
          }}
        >
          Let’s work on a project together
        </h3>
        <div
          className="inquiry-subheader"
          style={{
            textAlign: "left",
          }}
        >
          Fill the form below to help us serve you better.
        </div>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSerqbingv1zXbGbN-u30jT6RQZjrLrLk8jU0FnP_UN_48DFdw/viewform?embedded=true"
          width="100%"
          height="1730"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="Services"
        >
          Loading…
        </iframe>
        {/* <ServiceInquiry
          location="page"
          addService={addService}
          services={services}
        />
        <PlatformInquiry
          location="page"
          addPlatform={addPlatform}
          platforms={platforms}
        />
        <ContactInquiry
          location="page"
          changeContactValues={changeContactValues}
          contactValues={contactValues}
        />
        <button
          className="inquiry-button next-button"
          style={{
            marginTop: 48,
            width: "100%",
          }}
          onClick={submitValues}
        >
          SUBMIT
        </button> */}
      </div>
    </div>
  )
}

export default ServicesFormSection
